body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #E9EFF3;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.niu {
  position: absolute;
  top: 0;
  height:100vh;
  width:100vw;
  right: -8%;
  z-index: -1;
}

.home_btn {
  margin-top: 33px !important;
  width: 500px;
  height: 55px;
  background: #7BC5F1 !important;
  border-radius: 10px !important;
  font-size: 18px;
  color: #FAFAFA !important;
  letter-spacing: 0.85px;
  outline: none !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

.screen_audio {
  color: #FAFAFA;
}
.style_input__3S9PC {
  width: 500px;
  height: 55px;
  background: #FFFFFF;
  border-radius: 51px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 35px;
  z-index: 10;
  position: relative;
}

.style_input__3S9PC input, select {
  opacity: 0.86;
  font-family: PingFangSC-Light;
  font-size: 14px;
  color: #4B5369;
  height: 100%;
  width: 100%;
  letter-spacing: 0.66px;
  text-align: left;
  background: none;
  outline: none;
  border: none;
}
.hint {
  opacity: 0.68;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.62px;
  text-align: left;
  width: 100%;
  padding-left: 35px;
  margin-bottom: 5px;
}

.home_user {
  margin-bottom: 38px;
  margin-top: 30px;
  opacity: 0.61;
  font-family: PingFangSC-Regular;
  font-size: 28px;
  color: #4B5369;
  letter-spacing: 0.41px;
  text-align: center;
}
.qnMobile{
    position: relative;
    top:0;
    overflow: scroll;
}
.qnMobile .input {
    width:90vw;
    max-width: 500px;
}
.qnMobile .home_btn{
    width: 90vw;
    max-width: 500px;
}
.qnMobile .niu {
    top:0;
    left:0;
    width:100%
}
.qnMobule .style_input{
    width:90vw
}
.qnMobile .radioGroup{
    width:100vw
}

.col-2 {
    width: 16.666%;
}
  
.col-4 {
    width: 33.333%;
}

.col-6 {
    width: 50%;
}

.col-12 {
    width: 100%;
}

.row-4 {
    height: 33.33%;
}

.row-6 {
    height: 50%;
}

.row-12 {
    height: 100%;
}
.style_input__2r98K {
  width: 500px;
  height: 55px;
  background: #49494B;
  border-radius: 51px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 35px;
  z-index: 10;
  position: relative;
}

.style_input__2r98K select {
  opacity: 0.86;
  font-family: PingFangSC-Light;
  font-size: 14px;
  color: #FAFAFA;
  height: 100%;
  width: 100%;
  letter-spacing: 0.66px;
  text-align: left;
  background: none;
  outline: none;
  border: none;
}

.UserPlayer_root__3mujj {
  position: relative;
  background: #000;
  width: 200px;
  height: 150px;
}
.UserPlayer_rootMobile__7_d-J {
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
}
.UserPlayer_rootMobile__7_d-J video {
  object-fit: cover!important;
}
.UserPlayer_zoom__2XcgY {
  width: 200px;
  height: 150px;
}
.UserPlayer_zoomMobile__3RgtJ {
  width: 100%;
  height: 100%;
}
.UserPlayer_screen__3PMyz {
  width: 100%;
  height: 100%;
}
.UserPlayer_rootMobile__7_d-J>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.UserPlayer_rootMobile__7_d-J>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.UserPlayer_rootMobile__7_d-J>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}
.UserPlayer_rootMobile__7_d-J>div:nth-child(3) {
  display: none;
}
.UserPlayer_root__3mujj>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.UserPlayer_root__3mujj>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.UserPlayer_root__3mujj>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}
.UserPlayer_root__3mujj>div:nth-child(3) {
  display: none;
}
.UserPlayer_userName__3x0ym {
  left: 0;
  width: 100%;
  position: absolute;
  text-align: center;
}
